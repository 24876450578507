<template>
    <mdb-btn-group size="sm">
        <mdb-btn class="px-3 py-2"
                 :disabled="nowPage<=1"
                 @click="nowPage<=1 ? '' : switch_page(1)"
                 flat
                 darkWaves>第一頁</mdb-btn>
        <mdb-btn class="px-3 py-2"
                 :disabled="nowPage<=1"
                 @click="nowPage<=1 ? '' : switch_page(nowPage-1)"
                 flat
                 darkWaves>
            <i class="fas fa-angle-double-left"></i>
        </mdb-btn>
        <mdb-btn v-for="(p,pk) in get_total_page"
                 class="px-3 py-2"
                 :key="`p_${pk}`"
                 @click="switch_page(p)"
                 color="info"
                 :flat="p!=nowPage"
                 :darkWaves="p!=nowPage">{{p}}</mdb-btn>
        <mdb-btn next
                 class="px-3 py-2"
                 :disabled="nowPage>=totalPage"
                 @click="nowPage>=totalPage ? '' : switch_page(nowPage+1)"
                 flat
                 darkWaves><i class="fas fa-angle-double-right"></i></mdb-btn>
        <mdb-btn class="px-3 py-2"
                 :disabled="nowPage>=totalPage"
                 @click="nowPage>=totalPage ? '' : switch_page(totalPage)"
                 flat
                 darkWaves>最後頁</mdb-btn>
    </mdb-btn-group>
</template>
<script>
import { mdbBtn, mdbBtnGroup } from "mdbvue";
export default {
  name: "page",
  props: {
    nowPage: { default: 0 },
    totalPage: { default: 0 },
  },
  components: {
    mdbBtn,
    mdbBtnGroup,
  },
  computed: {
    get_total_page() {
      let output = [],
        limit = 5,
        count = 1;
      if (this.totalPage > limit) {
        let top_limit = this.nowPage + (limit - 1) / 2,
          bottom_limit = this.nowPage - (limit - 1) / 2;
        if (top_limit <= this.totalPage && bottom_limit >= 1) {
          count = bottom_limit;
        } else {
          if (this.totalPage < top_limit) {
            count = this.totalPage - limit;
          } else {
            count = 1;
          }
        }
      } else {
        // 總頁數小於指定最大頁數
        limit = this.totalPage;
      }
      for (let i = 1; i <= limit; i++) {
        output.push(count++);
      }
      return output;
    },
  },
  methods: {
    switch_page(pg) {
      console.info("switchpage", pg);
      this.$emit("switchpage", pg);
    },
  },
};
</script>